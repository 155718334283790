import React from 'react'
import DesktopFooter from './DesktopFooter'
import MobileFooter from './MobileFooter'

export default function Footer({path, screenSize, storeInfo}) {



  if (screenSize === "desktop") {
    return (
      <div className='w-screen absolute bottom-0 bg-[#0000000f]'>
        <DesktopFooter storeInfo={storeInfo} path={path} />
      </div>
    )
  }
  if (screenSize === "mobile")
    return (
      <div className='w-screen absolute bottom-0 bg-[#0000000f]'>
        <MobileFooter storeInfo={storeInfo} path={path} />
      </div>
    )
}
