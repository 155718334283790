import React from 'react'
import RouterButton from '../components/buttons/RouterButton'
import PlaceholderImg from '../img/prod-image.jpg'
import Gradient from '../components/Gradient'
import Animation from '../components/Animation'

export default function AboutProd({ screenSize, animate }) {

  const animationDuration = 1000;

  if (screenSize === "desktop") {
    return (
      <Animation duration={animationDuration} enter="opacity-0 left-[100%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
        <div className='w-screen min-h-screen flex justify-center items-center'>
          <Gradient blur={`${screenSize === "desktop" ? 200 : 100}`} color="pink" size={`${screenSize === "desktop" ? 400 : 300}`} top="40%" left="20%" />
          <div className='z-10 flex justify-center items-start'>
            <Animation duration={animationDuration} animate={animate} delay="300" enter="opacity-0 left-[-50%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
              <div>
                <RouterButton back to="/shop" style="transparent" text="Go back to Shop" />
                <div className='flex justify-center items-center'>
                  <div>
                    <div className='h-[500px] w-[30px] bg-orange-900'></div>
                    <hr className='my-3 border-black w-[30px]'></hr>
                    <p className='abril'>0.6cm</p>
                  </div>

                  <div className='mx-4'>
                    <div className='flex justify-center items-center'>
                      <img src={PlaceholderImg} className="w-[500px] border-[5px] border-orange-900" />
                      <div className='flex justify-center items-center'>
                        <div className='h-[500px] mx-3 border-l-[1px] border-black'></div>
                        <p className='abril m-auto text-center'>40cm</p>
                      </div>
                    </div>
                    <div className=''>
                      <hr className='my-3 border-black w-[500px]'></hr>
                      <p className='abril m-auto text-center'>40cm</p>
                    </div>
                  </div>
                </div>
              </div>
            </Animation>
            <Animation duration={animationDuration} delay="400" enter="opacity-0 left-[50%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
              <div className='w-[500px]'>
                <p className='text-center text-3xl mx-6 w-[80%] mx-auto mb-16'>Fine art print quality on a 100% recycled wood frame</p>
                <p className='w-[400px] m-auto'>The artworks are printed with pigment inks (ultrachrome), on kozo 110 thick paper.
                  Kozo paper is a traditional Japanese paper prepared from mulberry fiber (paper mulberry / Broussonetia papyrifera) which distinguishes it as a thick and non-homogeneous texture.  Its neutral ph makes it suitable for fineart prints and museum archiving.
                  The 40x40cm printed sheet is then applied by hand to a 6mm thick MDF panel, adding the wooden spacer and supports on the back to obtain the finished product.</p>
              </div>
            </Animation>
          </div>
        </div>
      </Animation>
    )
  }
  if (screenSize === "mobile") {
    return (
      <Animation duration={animationDuration} enter="opacity-0 top-[-300px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
        <div className='w-screen min-h-screen flex justify-center items-center'>
          <Gradient blur={`${screenSize === "desktop" ? 200 : 100}`} color="pink" size={`${screenSize === "desktop" ? 400 : 300}`} top="40%" left="20%" />
          <div className='z-10 flex justify-center items-start flex-wrap mt-20 mb-36'>
            <div className='flex flex-col justify-center items-center'>
              <RouterButton to="/shop" style="transparent" text="Go back to Shop" />
              <p className='text-center text-3xl mx-6 w-[80%] mx-auto mb-10'>Fine art print quality on a 100% recycled wood frame</p>
              <Animation duration={animationDuration} animate={animate} delay="400" enter="opacity-0 left-[-50%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
                <div className='flex justify-center items-center rotate-3 translate-x-3'>
                  <div className='flex flex-col justify-center items-center'>
                    <div className='h-[200px] w-[10px] bg-orange-900'></div>
                    <hr className='my-3 w-[10px] border-black'></hr>
                    <p className='abril text-sm'>0.6cm</p>
                  </div>

                  <div className='mx-3'>
                    <div className='flex justify-center items-center'>
                      <img src={PlaceholderImg} className="w-[200px] border-[5px] border-orange-900" />
                      <div className='flex justify-center items-center'>
                        <div className='h-[200px] mx-3 border-l-[1px] border-black'></div>
                        <p className='abril m-auto text-center text-sm'>40cm</p>
                      </div>
                    </div>
                    <div className=''>
                      <hr className='my-3 border-black w-[200px]'></hr>
                      <p className='abril m-auto text-center text-sm'>40cm</p>
                    </div>
                  </div>
                </div>
              </Animation>
            </div>

            <p className='w-[300px] m-auto mt-11'>The artworks are printed with pigment inks (ultrachrome), on kozo 110 thick paper.
              Kozo paper is a traditional Japanese paper prepared from mulberry fiber (paper mulberry / Broussonetia papyrifera) which distinguishes it as a thick and non-homogeneous texture.  Its neutral ph makes it suitable for fineart prints and museum archiving.
              The 40x40cm printed sheet is then applied by hand to a 6mm thick MDF panel, adding the wooden spacer and supports on the back to obtain the finished product.
            </p>
          </div>
        </div>
      </Animation>
    )
  }

}
