import React from 'react'
import MobileMenu from './MobileMenu';
import DekstopMenu from './DekstopMenu';

export default function Header({ screenSize, cartItems, path, scroll }) {
  if (screenSize === "desktop") {
    return (
      <DekstopMenu cartItems={cartItems} scroll={scroll} path={path} />
    )
  }
  if (screenSize === "mobile") {
    return (
      <MobileMenu cartItems={cartItems} scroll={scroll}  path={path} />
    )
  }
}
