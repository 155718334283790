import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Animation({ animate, trigger, children, duration, delay, enter, normal, exit, className }) {

  const [animation, setAnimation] = useState(enter)

  const handleExit = () => {
    setAnimation(exit)
  }

  useEffect(() => {
    setAnimation(normal);
    return () => { handleExit() }
  }, [trigger])

  if (animate === false) {
    return (
      <div>
        {children}
      </div>
    )
  }
  return (
    <div style={{ transitionDelay: `${delay}ms` || "0ms", transitionDuration: `${duration}ms` || "300ms" }} className={`p-0 m-0 animation relative transition-all ${animation} ${className || null}`}>
      {children}
    </div>
  )
}
