import React, {useState} from 'react'
import Alert from '../Alert';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import {IoIosRemoveCircleOutline} from 'react-icons/io';

export default function RemoveItem({id, quantity, removeOneElement}) {

  const loading = <AiOutlineLoading3Quarters className="animate-spin my-1" />;
  const remove = <IoIosRemoveCircleOutline className="" />;
  const [buttonText, setButtonText] = useState(remove);
  const [isAlert, setIsAlert] = useState(false);
  const alert = "Removed from cart"

    const removeItem = async (id, quantity) => {
      setButtonText(loading);
      try {
        await removeOneElement(id, (quantity-1));
        setButtonText(remove);
        setIsAlert(true);
        setTimeout(() => {
          setIsAlert(false);
        }, 1500);
      } catch (error) {
        setButtonText(remove);
      }
    };

  return (
    <dvi>
{/*       <Alert isAlert={isAlert} text={alert || ""} /> */}
      <button onClick={() => {removeItem(id, quantity)}} className='m-1 scale-125'>{buttonText}</button>
    </dvi>
  )
}
