import React, { useEffect, useState, useRef } from 'react'
import { GoTriangleRight } from 'react-icons/go';


export default function Accordion({ title, text }) {

    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState(300);
    const ref = useRef(null)

    useEffect(() => {

    }, [isOpen]);

    useEffect(() => {
        setHeight(ref.current.clientHeight);
    }, [])

    return (
        <div className={`w-[100%] my-5 overflow-hidden h-auto transition-all duration-300`}  style={{maxHeight : ` ${isOpen ? `${height + 80}px` : "80px"}`}} onClick={() => { setIsOpen(!isOpen) }}>
            <div className='border-b-[1px] hover:cursor-pointer border-black flex justify-between items-center pb-3'>
                <p className='text-[20px] font-bold lg:text-3xl'>{title}</p>
                <GoTriangleRight className={`${isOpen ? "rotate-90" : "rotate-0"} transition-all duration-300`} />
            </div>
            <div ref={ref} className={`${isOpen ? "opacity-100" : "opacity-0"} transition-all duration-300 py-5`} dangerouslySetInnerHTML={{__html: text}}></div>
        </div>
    )
}
