import React from 'react'
import { Link } from "react-router-dom";
import { BsArrowRightCircle } from 'react-icons/bs';

export default function HrefButton(props) {

  const style = () => {
    switch (props.style) {
      case "dark":
        return "dark-button";
      case "transparent":
        return "transparent-button";
      case "white":
        return "white-button";
      default:
        return "dark-button";
    }
  }

  return (
    <a href={props.href} className={`block m-2 ${props.className}`}>
      <div className={`${style()} hover:w-[210px] transition-all duration-300 group flex justify-center items-center`}>
        <p className='transition-all left-0 relative duration-300 group-hover:left-[-10px]'>{props.text}</p>
        <BsArrowRightCircle className='absolute opacity-0 transition-all scale-0 duration-300 translate-x-14 group-hover:opacity-100 group-hover:scale-100 group-hover:translate-x-[80px]' />
      </div>
    </a>
  )
}
