import React from 'react'
//router
import {
    Outlet
} from "react-router-dom";
//components
import Header from './header/Header';
import Footer from './footer/Footer';

export default function Container({screenSize, cartItems, path, scroll, storeInfo}) {
    return (
        <div className='absolute top-0 left-0 min-h-screen'>
            <Header screenSize={screenSize} scroll={scroll} path={path} cartItems={cartItems} />
                <Outlet />
            <Footer storeInfo={storeInfo} screenSize={screenSize} path={path}/>
        </div>
    )
}
