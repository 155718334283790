import React from 'react'
import { VscMail } from 'react-icons/vsc';
import { IoLogoInstagram } from 'react-icons/io';
import { FiMapPin } from 'react-icons/fi';
import { BsTelephone } from 'react-icons/bs';
import RouterButton from '../components/buttons/RouterButton';
import ContactForm from '../components/ContactForm';
import Gradient from '../components/Gradient';
import Animation from '../components/Animation';

export default function Contact({ screenSize, animate, storeInfo }) {

  const animationDuration = 1000;

  if (screenSize === "desktop") {
    return (
      <Animation duration={animationDuration} enter="opacity-0 top-[1000px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
        <div className='w-screen min-h-screen flex  items-center justify-center'>
          <Gradient blur="300" color="green" size="500" top="50%" left="10%" />
          <div className='z-10 w-[90%]'>
            <Animation duration={animationDuration} animate={animate} delay="300" enter="opacity-0 top-[-300px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
              <div className='flex items-center justify-around'>
                <p className='abril text-6xl lg:text-8xl'>Questions?</p>
                <div className='flex flex-col items-center justify-center'>
                  <p className='text-center text-2xl lg:text-3xl mx-6 w-[500px] mx-auto'>If you have any problem, write me. But before, check the FAQ section</p>
                  <RouterButton to="/faq" style="dark" text="Go to FAQ" />
                </div>
              </div>
            </Animation>
            <div className='flex justify-center items-center my-10'>
              <Animation duration={animationDuration} animate={animate} delay="500" enter="opacity-0 left-[-30%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
                <div className='flex flex-col justify-center items-start w-[300px]'>
                  {/* <p className='flex items-center my-4 text-2xl' ><BsTelephone className='mr-2' /> {storeInfo.telephone}</p> */}
                  <a href="https://www.instagram.com/loopcube_/" className='flex items-center my-4 text-2xl' ><IoLogoInstagram className='mr-2' /> Loopcube</a>
                  <p className='flex items-center my-4 text-2xl' ><VscMail className='mr-2' /> {storeInfo.email}</p>
                  <p className='flex items-center my-4 text-2xl' ><FiMapPin className='mr-2' /> Milan, italy</p>
                </div>
              </Animation>
              <div className='border-black border-l-[1px] h-[300px] mx-11'></div>
              <Animation duration={animationDuration} animate={animate} delay="500" enter="opacity-0 left-[30%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
                <ContactForm screenSize={screenSize} />
              </Animation>
            </div>
          </div>
        </div>
      </Animation>
    )
  }
  if (screenSize === "mobile") {
    return (
      <Animation duration={animationDuration} enter="opacity-0 left-[100%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
        <div className='w-screen min-h-screen flex  items-center justify-center'>
          <Gradient blur="200" color="green" size="300" top="30%" left="-20%" />
          <div className='z-10 w-[90%] my-36 flex flex-col items-center justify-center'>
            <div className='flex flex-col justify-center items-start border-l-[1px] border-black pl-4'>
{/*               <Animation duration={animationDuration} animate={animate} delay="200" enter="opacity-0 left-[-50%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
                <p className='flex items-center my-2 text-xl' ><BsTelephone className='mr-2' /> {storeInfo.telephone}</p>
              </Animation> */}
              <Animation duration={animationDuration} animate={animate} delay="300" enter="opacity-0 left-[-50%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
                <a href="https://www.instagram.com/loopcube_/" className='flex items-center my-2 text-xl' ><IoLogoInstagram className='mr-2' /> Loopcube</a>
              </Animation>
              <Animation duration={animationDuration} animate={animate} delay="400" enter="opacity-0 left-[-50%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
                <p className='flex items-center my-2 text-xl' ><VscMail className='mr-2' /> {storeInfo.email}</p>
              </Animation>
              <Animation duration={animationDuration} animate={animate} delay="500" enter="opacity-0 left-[-50%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
                <p className='flex items-center my-2 text-xl' ><FiMapPin className='mr-2' /> Milan, italy</p>
              </Animation>
            </div>
            <Animation duration={animationDuration} animate={animate} delay="600" enter="opacity-0 top-[200px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
              <div className='flex flex-col items-center justify-center my-10'>
                <p className='abril text-5xl my-5'>Questions?</p>
                <p className='text-center text-xl mx-6 mx-auto'>If you have any problem, write me. But before, check the FAQ section</p>
                <RouterButton to="/faq" style="dark" text="Go to FAQ" />
              </div>
            </Animation>
            <ContactForm screenSize={screenSize} />
          </div>
        </div>
      </Animation>
    )
  }

}
