import React, { useEffect, useRef, useState } from 'react'
import AwaitButton from '../buttons/AwaitButton'
import FnButton from '../buttons/FnButton'
import Commerce from '../../lib/Commerce'

import {CgRuler} from 'react-icons/cg';
import {GiWeight} from 'react-icons/gi';
import {BiTimer} from 'react-icons/bi';

export default function ShopElement({ prod, addToCart, scrollPos, screenSize }) {

    const [imageLoaded, setImageLoaded] = useState(false)
    const [inCenter, setInCenter] = useState(false)
    const el = useRef()

    useEffect(() => {
        if (isReadyToLoadImage() && !imageLoaded) {
            const img = new Image();
            img.src = prod.image.url;
            img.onload = () => {
                setImageLoaded(img.src);
            }
        }
        if (isInCenter()) {
            setInCenter(true);
            return
        }
        setInCenter(false);
    }, [scrollPos])

    const isReadyToLoadImage = () => {
        return el.current.getBoundingClientRect().top < window.innerHeight + 500
    }

    const isInCenter = () => {
        if (screenSize === "desktop")
            return el.current.getBoundingClientRect().top + el.current.getBoundingClientRect().height < window.innerHeight && el.current.getBoundingClientRect().top > 0
        if (screenSize === "mobile") {
            return el.current.getBoundingClientRect().top < window.innerHeight - 200
        }
    }


    const [isMoreDetail, setIsMoreDetail] = useState(false);
    const moreDetail = () => {
        setIsMoreDetail(!isMoreDetail);
    }

    return (
        <div className={`my-11  transition-all duration-500 ${inCenter ? "scale-100 opacity-100" : `${screenSize === "desktop" ? "scale-50 opacity-30" : null}`}`} ref={el}>
            <div className='flex justify-center items-center flex-wrap m-auto'>
                <div className={`w-[300px] h-[300px]  lg:w-[500px] lg:h-[500px] transition-all duration-300 bg-slate-300 bg-center bg-cover ${!imageLoaded ? "animate-pulse" : null}`} style={{ backgroundImage: `url(${imageLoaded})` }}></div>
                <div className={`mx-2 w-[300px] md:w-auto transition-all duration-500 delay-200 ${inCenter ? "scale-100" : `${screenSize === "desktop" ? "scale-0" : null}`}`}>
                    <div className=' py-2 md:p-2'>
                        <p className='abril text-2xl'>{prod.description.split(" ")[0]} #{prod.description.split(" ")[1]}</p>
                        <p className='arc-black text-4xl w-[300px] lg:w-[500px]'>{prod.name}</p>
                        <hr className='my-2 w-[300px] lg:w-auto border-black'></hr>
                        <p className='arc-black text-4xl'>{prod.price.formatted}€</p>
                    </div>
                    <div className='flex items-center flex-wrap'>
                        <AwaitButton alert="Element added to cart!" onClick={async () => { await addToCart(prod.id) }} style="dark" text="Add to cart" />
                        <FnButton style="transparent" text="More Details" onClick={() => {moreDetail()}} />
                    </div>
                    <div className={`${isMoreDetail ? "h-[100px] opacity-100" : "h-[0px] opacity-0"} py-2 md:p-2 transition-all duration-300`}>
                        <div className='flex items-center'><CgRuler className='mr-3'/> 40cm x 40cm</div>
                        <hr className='border-black mb-2 w-[200px]'/>
                        <div className='flex items-center'><GiWeight className='mr-3'/> 800gr</div>
                        <hr className='border-black mb-2 w-[200px]'/>
                        <div className='flex items-center'><BiTimer className='mr-3'/> 2 weeks</div>
                        <hr className='border-black mb-2 w-[200px]'/>
                    </div>
                </div>
            </div>

        </div>
    )
}
