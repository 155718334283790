import React from 'react'
import RouterButton from '../components/buttons/RouterButton'

export default function NotFound() {
  return (
    <div className='flex justify-center flex-col items-center w-screen min-h-screen'>
        <p className='arc-black text-8xl'>404</p>
        <p className='text-2xl'>There is nothing here :(</p>
        <RouterButton to="/" text="Go back home" /> 
    </div>
  )
}
