import React from 'react'
import RouterButton from '../components/buttons/RouterButton'
import Gradient from '../components/Gradient'
import Background from '../img/background.jpg'
import Pattern from '../img/bg-pattern.jpg'
import Animation from '../components/Animation'

export default function Home({ screenSize, animate }) {

  const animationDuration = 1000;

  if (screenSize === "desktop") {
    return (
      <Animation duration={animationDuration} enter="opacity-0 left-[-100%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
        <div className={`bg-gray-600 flex justify-center flex-col items-center h-screen w-screen relative overflow-hidden`} style={{ backgroundImage: `url(${Pattern})` }}>
          <img className='absolute z-0 -right-32' src={Background} />
          <Gradient blur="300" color="#f9a8d4" size="500" top="50%" left="10%" />
          <div className='w-[50%] z-10'>
            <div className='w-[650px]'>

              <Animation duration={animationDuration} animate={animate} enter="left-[-50%] opacity-0" delay="300" normal="left-[0%] opacity-100" exit="left-[50%] opacity-0">
                <p className=' abril text-8xl opacity-25'>LOOP</p>
              </Animation>

              <Animation duration={animationDuration} animate={animate} enter="left-[-50%] opacity-0" delay="400" normal="left-[0%] opacity-100" exit="left-[50%] opacity-0">
                <p className='abril text-8xl opacity-50'>LOOP</p>
              </Animation>

              <Animation duration={animationDuration} animate={animate} enter="left-[-50%] opacity-0" delay="500" normal="left-[0%] opacity-100" exit="left-[50%] opacity-0">
                <p className='abril text-8xl'>LOOP <span className='arc-black text-8xl text-outline'>CUBE</span></p>
                <div className='flex items-center justify-between'>
                  <div>
                    <RouterButton to="/shop" style="dark" text="See all illustration" />
                    <RouterButton to="/contact" style="transparent" text="Contact me" />
                  </div>
                  <p className='text-center text-3xl w-[50%]'>Architectural Inspired
                    Illustration.</p>
                </div>
              </Animation>
            </div>
          </div>

        </div>
      </Animation>
    )
  }
  if (screenSize === "mobile") {
    return (
      <Animation duration={animationDuration} enter="opacity-0 top-[500px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
        <div className='w-screen min-h-screen flex justify-center items-center' style={{ backgroundImage: `url(${Background})`, backgroundPosition: "40% -0%" }}>
          <Gradient blur="200" color="#f9a8d4" size="300" top="50%" left="-20%" />
          <div className='z-10 flex flex-col items-center justify-center'>
            <Animation duration={animationDuration} animate={animate} delay="300" enter="opacity-0 left-[-100%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
              <p className='abril text-5xl'>LOOP <span className='arc-black text-5xl text-outline'>CUBE</span></p>
            </Animation>
            <Animation duration={animationDuration} animate={animate} delay="600" enter="opacity-0" normal="opacity-100" exit="left-[-100%]">
            <p className='text-center text-xl'>Architectural Inspired Illustration.</p>
            </Animation>
            <Animation duration={animationDuration} animate={animate} delay="1000" enter="opacity-0 top-[100px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
              <RouterButton to="/shop" style="dark" text="See all illustration" />
              <RouterButton to="/contact" style="transparent" text="Contact me" />
            </Animation>
          </div>
        </div>
      </Animation >
    )
  }

}
