import React from 'react'
import { Link } from "react-router-dom";
import Logo from "../../img/logo.png"
import CartIcon from './CartIcon';
import HamburgerMenu from './HamburgerMenu';

export default function MobileMenu({cartItems, path, scroll}) {
  return (
    <div className={`${path === "/nft" ? "invert" : null } w-screen fixed top-0 z-50 transition-all duration-300 bg-transparent`} style={scroll > 80 ? {boxShadow: "0px 0px 5px #0000001c", backgroundColor: "#f0f0f0fa"} : {}}>
      <div className='m-auto w-[100%] p-5 flex items-center justify-between transition-all duration-300' style={scroll > 80 ? {paddingTop:"5px", paddingBottom: "5px"} : {}}>
        <CartIcon cartItems={cartItems} />
        <Link to="/" ><img className='w-7' src={Logo}></img></Link>
        <HamburgerMenu />
      </div>
    </div>
  )
}
