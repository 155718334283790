import React, { useState } from 'react';
import FnButton from './buttons/FnButton'
import AwaitButton from './buttons/AwaitButton';
import { FiAlertTriangle } from 'react-icons/fi';

export default function ContactForm({ screenSize }) {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");

    const [formError, setFormError] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const checkForm = () => {
        ;

        const nameRegx = /^[a-z ,.'-]+$/i;
        if (name.length < 3 && !nameRegx.test(name)) { setFormError(1); return false }

        const emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!emailRegx.test(email)) { setFormError(2); return false }

        const contentRegx = /[A-Za-z0-9 _.,!"'/$]{6,}/;
        if (!contentRegx.test(content)) { setFormError(3); return false }

        return true;
    }

    const sendForm = async () => {
        if (!checkForm()) {
            return
        }
        const bodyContent = { name: name, email: email, content: content };
        const response = await fetch("https://loopcubenew.herokuapp.com/mail", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyContent)
        });
        if(response.status === 200){
            setEmailSent(true);
        }
    }

    if (!emailSent) {
        if (screenSize === "desktop") {
            return (
                <div>
                    <form className='flex justify-start flex-col'>
                        <p className={`${formError === 1 ? "" : "hidden"} my-0 text-red-600 flex items-center`}><FiAlertTriangle /> Name is required and accept only letters and space</p>
                        <input onChange={(e) => setName(e.target.value)} className='input' placeholder='Your Name' type="text" />
                        <p className={`${formError === 2 ? "" : "hidden"} my-0 text-red-600 flex items-center`}><FiAlertTriangle /> Email is required, the format is name@domain</p>
                        <input onChange={(e) => setEmail(e.target.value)} className='input' placeholder='Your Email' type="text" />
                        <p className={`${formError === 3 ? "" : "hidden"} my-0 text-red-600 flex items-center`}><FiAlertTriangle /> Max 150 characters, only letters, number, space and punctuation</p>
                        <input onChange={(e) => setContent(e.target.value)} maxLength='150' className='input' placeholder='Reason for contact' type="text" />
                        <AwaitButton onClick={() => sendForm()} text="Send form" type="transparent" />
                    </form>
                </div>
            )
        }

        if (screenSize === "mobile") {
            return (
                <div>
                    <form className='flex justify-start flex-col'>
                        <input className='input-mobile' placeholder='Your Name' type="text" />
                        <input className='input-mobile' placeholder='Your Email' type="text" />
                        <input className='input-mobile' placeholder='Reason for contact' type="text" />
                        <FnButton onClick={() => sendForm()} text="Send form" type="transparent" />
                    </form>
                </div>
            )
        }
    }
    if(emailSent){
        return (
            <div className='w-[300px]'>
                <p className='text-4xl'>Your request was correctly sent!</p>
                <hr className='border-black my-2' />
                <p className='text-xl'>We will respond as soon as possible</p>
            </div>
        )
    }

}
