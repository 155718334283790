import React, {useState} from 'react'
import ShopElement from './ShopElement'
import Animation from '../Animation'

export default function ShopCarousel({ products, scroll, addToCart, animate, screenSize }) {

  const compareFn = (a,b) => {
    if ( parseInt(a.description.split(" ")[1]) < parseInt(b.description.split(" ")[1]) ){
      return -1;
    }
    if ( parseInt(a.description.split(" ")[1]) > parseInt(b.description.split(" ")[1])){
      return 1;
    }
    return 0;
  }

  const sortedProd = products.sort( compareFn );

  return (
    <Animation duration={500} animate={animate} delay="300" enter="opacity-0 top-[300px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
      <div className='flex flex-col justify-center items-center m-auto'>
        {
          sortedProd.map(prod => (
            <ShopElement scrollPos={scroll} screenSize={screenSize} key={prod.id} prod={prod} addToCart={addToCart} />
          ))
        }
      </div>
    </Animation>
  )
}
