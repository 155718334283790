import React from 'react'
import { TbChevronsDownLeft } from 'react-icons/tb';
import { Link } from "react-router-dom";
import Logo from "../../img/logo.png"
import CartIcon from './CartIcon';

export default function DekstopMenu({cartItems, path, scroll}) {
    return (
        <div className={`${path === "/nft" ? "invert" : null } w-screen fixed top-0 z-50 transition-all duration-300 bg-transparent `} style={scroll > 80 ? {boxShadow: "0px 0px 5px #0000001c", backgroundColor: "#fcfcfc"} : {}}>
            <div className='m-auto w-[90%] p-5 flex items-center justify-around transition-all duration-300' style={scroll > 80 ? {paddingTop:"5px", paddingBottom: "5px"} : {}}>
                <Link to="/" ><img className='w-10' src={Logo}></img></Link>
                <div className='flex justify-center items-center'>
                    <Link className='mx-10' to="/shop">Shop</Link>
                    <Link className='mx-10' to="/about">About</Link>
                    <Link className='mx-10' to="/nft">NFT</Link>
                    <Link className='mx-10' to="/contact">Contact</Link>
                    <CartIcon className="mx-10" cartItems={cartItems} />
                </div>
            </div>
        </div>
    ) 
}
