import React from 'react'
import RouterButton from '../components/buttons/RouterButton'
import HrefButton from '../components/buttons/HrefButton'
import Gradient from '../components/Gradient'
import Chiara from '../img/chiara-amato.png'
import Animation from '../components/Animation'

export default function About({ screenSize, animate }) {

  const animationDuration = 1000;

  if (screenSize === "desktop") {
    return (
      <Animation duration={animationDuration} enter="opacity-0 left-[-100%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
        <div className='flex justify-center items-center w-screen min-h-screen my-16'>
          <Gradient blur="300" color="orange" size="500" top="50%" left="10%" />
          <div className='z-10'>
            <Animation duration={animationDuration} animate={animate} delay="300" enter="left-[-200%]" normal="left-[0%]" exit="left-[-100%]">
              <div className='mb-16'>
                <p className='abril text-8xl'>Chiara</p>
                <p className='text-outline arc-black text-8xl translate-x-10' >Amato</p>
              </div>
            </Animation>
            <Animation duration={animationDuration} animate={animate} delay="400" enter="left-[-200%]" normal="left-[0%]" exit="left-[-100%]">
              <img className='w-[350px]' src={Chiara}></img>
            </Animation>
          </div>

          <div className='h-[500px] mx-28 border-l-[1px] border-black'></div>

          <div className='w-[30%] z-10 flex flex-col items-center justify-center'>
            <Animation duration={animationDuration} animate={animate} delay="300" enter="left-[200%]" normal="left-[0%]" exit="left-[-200%]">
              <p className='text-center text-3xl mx-6 w-[80%] mx-auto mb-16'>A young artist who has the roots of her creative production in Architecture.</p>
              <p className=' m-auto mb-8 w-[90%]'>Born in Luino, Milan based, Chiara attended Politecnico di Milano with a constant hunger for growth and a critical spirit.
                During the academic years she's been inextricably linked to the creative thinking at the base of each Project, making experimentation his own guide.
                <br /><br />
                Thus was born Loopcube, artistic and architectural research experiment.
                The Project unearths the theme of infinity through the finite language of the cube.
                <br /><br />
                Having defined borders and adjustment limits of the camera lens, how many times is it possible to break the visual and conceptual patterns?
                <br /><br />
                Embracing this challenge, the cultural thoughts applied to the world of Loopcube leave space for any stylistic, graphic and structural choices. Everything is allowed. The limit doesn't exist. Loopcube is freedom.
              </p>
              <RouterButton to="/shop" style="dark" text="See all illustration" />
            </Animation>
          </div>

        </div>
      </Animation>
    )
  }
  if (screenSize === "mobile") {
    return (
      <Animation duration={animationDuration} enter="opacity-0 left-[-100%]" normal="opacity-100 left-[0%]" exit="left-[-100%}">
        <div className='flex justify-center items-center w-screen min-h-screen my-24'>
          <Gradient blur="150" color="orange" size="200" top="50%" left="0%" />
          <div className='flex flex-col items-center justify-center z-10'>
            <div className=' -translate-x-5'>
              <p className='abril text-5xl'>Chiara</p>
              <p className='text-outline arc-black text-5xl translate-x-10' >Amato</p>
            </div>


            <div className="w-[80%] left-[10%] relative flex flex-col justify-center items-center bg-no-repeat py-16">
                <img src={Chiara} className="absolute -z-10 left-[-70%] w-[300px]"></img>
              <Animation duration={animationDuration} animate={animate} delay="500" enter="opacity-0 top-[200px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
                <p className='text-center text-2xl mx-6 w-[80%] mx-auto'>A young artist who has the roots of her creative production in Architecture.</p>
                <div>
                  <RouterButton to="/shop" style="dark" text="See all illustration" />
                  <HrefButton href="#about-me" style="transparent" text="More about me" />
                </div>
              </Animation>
            </div>

            <div className='flex flex-col justify-center items-center mb-11'>
              <p className=' m-auto mb-5 w-[80%]' id="about-me">Born in Luino, Milan based, Chiara attended Politecnico di Milano with a constant hunger for growth and a critical spirit.
                During the academic years she's been inextricably linked to the creative thinking at the base of each Project, making experimentation his own guide.
                <br /><br />
                Thus was born Loopcube, artistic and architectural research experiment.
                The Project unearths the theme of infinity through the finite language of the cube.
                <br /><br />
                Having defined borders and adjustment limits of the camera lens, how many times is it possible to break the visual and conceptual patterns?
                <br /><br />
                Embracing this challenge, the cultural thoughts applied to the world of Loopcube leave space for any stylistic, graphic and structural choices. Everything is allowed. The limit doesn't exist. Loopcube is freedom.
              </p>
              <RouterButton to="/shop" style="dark" text="See all illustration" />
            </div>

          </div>
        </div>
      </Animation>
    )
  }
}
