import React, { useState } from 'react'
import Loading from '../components/Loading'
import CartElement from '../components/cart/CartElement'
import FnButton from '../components/buttons/FnButton'
import AwaitButton from '../components/buttons/AwaitButton'
import RouterButton from '../components/buttons/RouterButton'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import Animation from '../components/Animation'
import Gradient from '../components/Gradient'

export default function Cart({ cart, removeFromCart, emptyCart, screenSize, removeOneElement }) {

    const animationDuration = 1000;
    
    if (cart.line_items) {
        {/*something in the cart */ }
        if (cart.line_items.length > 0) {
            return (
                <Animation duration={animationDuration} delay="100" enter="opacity-0 top-[-300px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
                <div className={`${screenSize === "desktop" ? "mt-40" : "mt-14"} mb-20 flex flex-col items-center justify-start w-screen min-h-screen`}>
                    <Gradient blur={`${screenSize === "desktop" ? "300" : "200" }`} color="pink" size={`${screenSize === "desktop" ? "500" : "400" }`} top={`${screenSize === "desktop" ? "50%" : "30%" }`}  left={`${screenSize === "desktop" ? "70%" : "0%" }`} />
                    <div className={`z-10 flex flex-col items-center justify-center ${screenSize === "desktop" ? "w-[50%]" : "w-[90%]"}`}>
                        <p className='text-left text-4xl abril my-5'>Cart</p>
                        <div className='w-[100%] transition-all duration-300'>
                            {
                                cart.line_items.map(prod => (
                                    <CartElement screenSize={screenSize} key={prod.id} prod={prod} removeOneElement={removeOneElement} removeFromCart={removeFromCart} />
                                ))
                            }
                        </div>
                        <p className='abril w-[100%] text-right'>Total</p>
                        <p className='arc-black text-4xl mb-2 w-[100%] text-right'>{cart.subtotal.formatted}€</p>

                        <div className='flex w-[100%] justify-end'>
                            <AwaitButton style="transparent" onClick={async () => { await emptyCart() }} text="Empty cart" />
                            <RouterButton style="dark" text="Checkout" to="/checkout" />
                        </div>
                    </div>
                </div>
                </Animation>
            )
        }
        {/*cart is empty*/ }
        return (
            <Animation duration={animationDuration} delay="100" enter="opacity-0 top-[200px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
                <div  className='w-screen h-screen flex flex-col items-center justify-center'>
                    <p className='abril text-3xl md:text-5xl my-5'>Cart is empty!</p>
                    <RouterButton to="/shop" style="dark" text="Go to Shop" />
                </div>
            </Animation>
        )
    }
    {/*cart not yet fetched*/ }
    return (
        <div className='w-screen h-screen flex flex-col items-center justify-center'>
            <Loading />
        </div>
    )
}
