import React from 'react'
import Logo from '../../img/logo.png'
import { VscMail } from 'react-icons/vsc';
import { IoLogoInstagram } from 'react-icons/io';
import { FiMapPin } from 'react-icons/fi';


export default function DesktopFooter({path, storeInfo}) {
  return (
    <div className={`${path === "/nft" ? "invert" : null } w-[70%] m-auto flex justify-around items-center p-5 `}>
        <img src={Logo} className="w-8"></img>
        <p className='arc-black font-bold text-lg'>LOOPCUBE</p>
        <div className='h-10 border-l-[1px] border-black'></div>
        <a href='https://www.instagram.com/loopcube_/' className='flex items-center justify-center'><IoLogoInstagram className='mx-1' /> loopcube</a>
        <a  className='flex items-center justify-center'><VscMail className='mx-1' /> {storeInfo.email}</a>
        <a  className='flex items-center justify-center'><FiMapPin className='mx-1' /> Milan, italy</a>
    </div>
  )
}
