import React, { useState, useEffect } from 'react'
import { AiOutlineMenu } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import { Link } from "react-router-dom";
import Animation from '../Animation';

export default function HamburgerMenu() {

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div onClick={() => { setMenuOpen(!menuOpen) }}>
            <AiOutlineMenu className="hover:cursor-pointer" />
            <div className={`opacity-[0.96] fixed flex flex-col items-center justify-center top-0 bg-white h-screen w-screen -z-10 transition-all duration-300 ${menuOpen ? "left-0" : "left-[100%]"} `}>
                <div className='flex flex-col items-center justify-center'>
                    <Animation duration="300" trigger={menuOpen} normal={`${menuOpen ? "opacity-100 left-[0%]" : "opacity-0 left-[100%]"}`} exit="left-[-100%]">
                        <Link className='mx-10 text-xl my-3' to="/shop">Shop</Link>
                    </Animation>
                    <Animation duration="400" trigger={menuOpen} normal={`${menuOpen ? "opacity-100 left-[0%]" : "opacity-0 left-[100%]"}`} exit="left-[-100%]">
                        <Link className='mx-10 text-xl my-3' to="/about">About</Link>
                    </Animation>
                    <Animation duration="500" trigger={menuOpen} normal={`${menuOpen ? "opacity-100 left-[0%]" : "opacity-0 left-[100%]"}`} exit="left-[-100%]">
                        <Link className='mx-10 text-xl my-3' to="/nft">NFT</Link>
                    </Animation>
                    <Animation duration="600" trigger={menuOpen} normal={`${menuOpen ? "opacity-100 left-[0%]" : "opacity-0 left-[100%]"}`} exit="left-[-100%]">
                        <Link className='mx-10 text-xl my-3' to="/contact">Contact</Link>
                    </Animation>
                </div>
            </div>
        </div>
    )
}
