import React, { useState } from 'react'
import { BsArrowRightCircle } from 'react-icons/bs';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import Alert from '../Alert';

export default function AwaitButton({ onClick, style, text, alert }) {

    const loading = <AiOutlineLoading3Quarters className="animate-spin my-1" />;
    const [buttonText, setButtonText] = useState(text);

    const [isAlert, setIsAlert] = useState(false);

    const manageOnClick = async () => {
        setButtonText(loading);
        try {
            await onClick();
            if(alert){
                setIsAlert(true);
                setTimeout(() => setIsAlert(false), 1500)
            }
            setButtonText(text);
        } catch (error) {
            setButtonText(text);
        }
    }

    const styleFn = () => {
        switch (style) {
            case "dark":
                return "dark-button";
            case "transparent":
                return "transparent-button";
            case "white":
                return "white-button";
            default:
                return "dark-button";
        }
    }

    return (
        <div className={`${styleFn()} hover:cursor-pointer hover:w-[210px] transition-all duration-300 group flex justify-center items-center m-2`} onClick={manageOnClick}>
            <Alert isAlert={isAlert} text={alert || ""} />
            <div className='transition-all left-0 relative duration-300 group-hover:left-[-10px]'>{buttonText}</div>
            <BsArrowRightCircle className='absolute opacity-0 transition-all scale-0 duration-300 translate-x-14 group-hover:opacity-100 group-hover:scale-100 group-hover:translate-x-[80px]' />
        </div>
    )
}
