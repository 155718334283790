import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { BsCart2 } from 'react-icons/bs';

export default function CartIcon({className, cartItems}) {

  return (
    <div className={`hover:cursor-pointer ${className}`}>
      <Link to="/cart">
        <BsCart2 />
        <p className={`transition-all duration-300 absolute translate-x-2 -translate-y-6 bg-red-600 white rounded-xl text-center text-white p-0 w-6 
        ${cartItems ? "scale-75" : "scale-0"} `} >
          {cartItems}
        </p>
      </Link>
    </div>
  )
}
