import React from 'react'
import RemoveAll from './RemoveAll'
import RemoveItem from './RemoveItem'

export default function CartElement({prod, removeFromCart,removeOneElement, screenSize}) {
    return (
        <div className='my-2 flex justify-start items-center m-auto w-[100%] flex-wrap bg-[#00000003] p-4 rounded-lg shadow-md'>
            <img className='w-[100px]' src={prod.image.url}></img>
            <div className='mx-2 flex justify-between flex-grow'>
                <div>
                    <p className='arc-black text-xl'>{prod.name}</p>
                    <hr></hr>
                    <p className='arc-black'>{prod.price.raw * prod.quantity}€</p>
                </div>
                <div className='my-2 mx-2 flex justify-start items-center min-w-[55px]'>
                    <p className=''>x {prod.quantity}</p>
                    {prod.quantity > 1 ? <RemoveItem id={prod.id} quantity = {prod.quantity} removeOneElement={removeOneElement} /> : null}
                    <RemoveAll id={prod.id} removeFromCart={removeFromCart} />
                </div>
            </div>
            <hr className={`w-[100%] my-2 ${screenSize === "mobile" ?  "hidden" : null}`}></hr>
        </div>
    )
}
