import React from 'react'
import { Link } from "react-router-dom";
import { BsArrowRightCircle } from 'react-icons/bs';

export default function FnButton(props) {

    const style = () => {
        switch (props.style) {
          case "dark":
            return "dark-button";
          case "transparent":
            return "transparent-button";
          case "white":
            return "white-button";
          default:
            return "dark-button";
        }
      }

    return (
        <div className={`${style()} hover:cursor-pointer hover:w-[210px] transition-all duration-300 group flex justify-center items-center m-2`} onClick={props.onClick}>
            <div className={`transition-all left-0 relative duration-300 group-hover:left-[${props.back ? "" : "-" }10px]`}>{props.text}</div>
            {
              props.back ? <BsArrowRightCircle className='absolute opacity-0 transition-all rotate-180 scale-0 duration-300 translate-x-14 group-hover:opacity-100 group-hover:scale-100 group-hover:translate-x-[-80px]' />
              : <BsArrowRightCircle className='absolute opacity-0 transition-all scale-0 duration-300 translate-x-14 group-hover:opacity-100 group-hover:scale-100 group-hover:translate-x-[80px]' />
            }
        </div>
    )
}
