import React from 'react'
import Logo from '../../img/logo.png'
import { VscMail } from 'react-icons/vsc';
import { IoLogoInstagram } from 'react-icons/io';
import { FiMapPin } from 'react-icons/fi';


export default function MobileFooter({path, storeInfo}) {
    return (
        <div className={`${path === "/nft" ? "invert" : null } w-[70%] m-auto flex justify-around items-center p-5`}>
            <div >
                <img src={Logo} className="w-5 block m-auto"></img>
                <p className='arc-black font-bold text-base'>LOOPCUBE</p>
            </div>
            <div className='h-10 border-l-[1px] mx-6 border-black'></div>
            <div className='flex flex-col items-start'>
                <a href='https://www.instagram.com/loopcube_/' className='flex items-center justify-center text-sm'><IoLogoInstagram className='mx-1' /> loopcube</a>
                <a  className='flex items-center justify-center text-sm'><VscMail className='mx-1' /> {storeInfo.email}</a>
                <a  className='flex items-center justify-center text-sm'><FiMapPin className='mx-1' /> Milan, italy</a>
            </div>
        </div>
    )
}
