import React from 'react'
import StripePaymentCard from './StripePaymentCard'

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const PUBLIC_KEY = "pk_live_51LAdipCkQCAxed7UOOvSXUS1G193DOYM3CLBYHmdRH8SVf2zBH8RvkzzJjQUO4KPNZtqWsmOaDPTBji924m4h9yZ00HzxDFJlj"; //live
//const PUBLIC_KEY = "pk_test_51LAdipCkQCAxed7U01qZfgxmc1h2ZSYsRlXLaMNub1Muf3UjT0taRAWcn6HFENnkpQhSqovQOzNKhTBAxQr7m1k900OQMcgW3p"; //test
const stripeTestPromise = loadStripe(PUBLIC_KEY);

export default function StripeElement({cart, getCheckoutToken, sendOrder, getCart, orderData}) {
  return (
    <Elements stripe={stripeTestPromise}>
      <StripePaymentCard orderData={orderData} getCheckoutToken={getCheckoutToken} sendOrder={sendOrder} getCart={getCart} cart={cart} />
    </Elements>
  )
}
