import React, { useState, useEffect } from 'react'
//components
import Container from './components/Container';
import Commerce from './lib/Commerce';
import ScrollTop from './components/ScrollTop';

//pages
import Home from './pages/Home';
import About from './pages/About';
import AboutProd from './pages/AboutProd';
import Shop from './pages/Shop';
import Nft from './pages/Nft';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import ThankYou from './pages/ThankYou';
import NotFound from './pages/NotFound';

//react router
import {
    Routes,
    Route,
    useLocation
} from "react-router-dom";
import { BsCheckLg, BsTelephone } from 'react-icons/bs';

//gtm
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-PCZ8KTH'
}
 
TagManager.initialize(tagManagerArgs)


localStorage.setItem("visitedPath", JSON.stringify([]))

export default function App() {

    //use location to get the path of the router
    const location = useLocation();
    const [animate, setAnimate] = useState(true);

    useEffect(() => {
        const ls = JSON.parse(localStorage.getItem("visitedPath"));

        if (!ls.includes(location.pathname)) {
            setAnimate(true);
            const ls = JSON.parse(localStorage.getItem("visitedPath"));
            ls.push(location.pathname);
            localStorage.setItem("visitedPath", JSON.stringify(ls));
            return
        }
        setAnimate(false);

    }, [location])



    //get window size to pass it to every component
    const getScreenSize = () => {
        if (window.innerWidth < 800) return "mobile";
        return "desktop"
    }
    const [screenSize, setScreenSize] = useState(getScreenSize());
    window.onresize = () => {
        setScreenSize(getScreenSize());
    }

    const[scroll, setScroll] = useState(0);
    window.onscroll = () => {
      setScroll(window.scrollY);
    }

    //cart commerce js
    const [cart, setCart] = useState({});

    const getCart = async () => {
        const cart = await Commerce.cart.retrieve();
        setCart(cart);
        return cart;
    }
    const addToCart = async (prodId) => {
        const { cart } = await Commerce.cart.add(prodId, 1);
        setCart(cart);
        return cart;
    }
    const removeFromCart = async (prodId) => {
        const { cart } = await Commerce.cart.remove(prodId);
        setCart(cart);
        return cart;
    }

    const removeOneElement = async (prodId, quantity) => {
        const { cart } = await Commerce.cart.update(prodId, { quantity: quantity });
        setCart(cart);
        return cart;
    }

    const emptyCart = async () => {
        const { cart } = await Commerce.cart.empty();
        setCart(cart);
        return cart;
    }

    //checkout commerce js
    const [checkout, setCheckout] = useState(null);
    const [order, setOrder] = useState(null);

    const getCheckoutToken = async () => {
        const checkout = await Commerce.checkout.generateToken(cart.id, { type: 'cart' });
        setCheckout(checkout);
        return checkout;
    }

    const sendOrder = async (checkoutId, orderData) => {
        const order = await Commerce.checkout.capture(checkoutId, orderData)
        setOrder(order);
        return order;
    }

    //prod commerce js
    const [products, setProducts] = useState([]);

    const getProducts = async () => {
        const products = await Commerce.products.list({ limit: 200 });
        setProducts(products.data);
        return products.data
    }

    //commerce js store info
    const [email, setEmail] = useState(null);
    const [telephone, setTelephone] = useState(null);

    const getStoreInfo = async () => {
        const info = await Commerce.merchants.about().then(el => el.data[0]).then(el => JSON.parse(el.description.replaceAll("<p>", "").replaceAll("</p>", "")));
        setEmail(info.email);
        setTelephone(info.telephone);
        console.log(info)
        return info
    }    

    useEffect(() => {
        setScreenSize(getScreenSize());
        getCart();
        getStoreInfo();
        getProducts();
    }, []);

    return (
        <ScrollTop>
            <Routes key={location.pathname} location={location}>
                <Route path="/" element={<Container path={location.pathname} storeInfo={{email,telephone}} scroll={scroll} screenSize={screenSize} cartItems={cart.total_items} />}>
                    <Route path='*' element={<NotFound />} />
                    <Route path="/" element={<Home animate={animate} screenSize={screenSize} />} />
                    <Route path="home" element={<Home animate={animate} screenSize={screenSize} />} />
                    <Route path="about" element={<About animate={animate} screenSize={screenSize} />} />
                    <Route path="aboutproduct" element={<AboutProd animate={animate} screenSize={screenSize} />} />
                    <Route path="shop" element={<Shop scroll={scroll}  animate={animate} screenSize={screenSize} products={products} addToCart={addToCart} />} />
                    <Route path="nft" element={<Nft animate={animate} screenSize={screenSize} />} />
                    <Route path="contact" element={<Contact animate={animate} storeInfo={{email,telephone}} screenSize={screenSize} />} />
                    <Route path="faq" element={<Faq animate={animate} screenSize={screenSize} />} />
                    <Route path="cart" element={<Cart animate={animate} screenSize={screenSize} cart={cart} removeOneElement = {removeOneElement} removeFromCart={removeFromCart} emptyCart={emptyCart} />} />
                    <Route path="checkout" element={<Checkout animate={animate} cartItems={cart.total_items} screenSize={screenSize} getCheckoutToken={getCheckoutToken} sendOrder={sendOrder} cart={cart} getCart={getCart} />} />
                    <Route path="thankyou" element={<ThankYou animate={animate} screenSize={screenSize} order={order} />} />
                </Route>
            </Routes>
        </ScrollTop>
    )
}
