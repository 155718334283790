import React, { useState, useEffect } from 'react'
import ShopCarousel from '../components/shopCarousel/ShopCarousel';
import Loading from '../components/Loading';
import RouterButton from '../components/buttons/RouterButton';
import HrefButton from '../components/buttons/HrefButton';
import Animation from '../components/Animation';
import Gradient from '../components/Gradient';

export default function Shop({ products, scroll, addToCart, screenSize, animate }) {

  const animationDuration = 1000;

  return (
    <Animation duration={animationDuration} enter="left-[-100%]" normal="left-[0%]" exit="left-[-100%]">
      <div className='w-screen min-h-screen flex items-center justify-center'>
        <Gradient blur="200" color="#5C56A6" size="350" top="30%" left="90%" />
        <div className='my-28 z-10'>
          <div className='flex justify-center items-center flex-wrap'>
            <Animation duration={animationDuration} animate={animate} delay="500" enter="opacity-0 left-[-100%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
              <div className='mb-5 md:mb-16'>
                <p className='abril text-5xl lg:text-8xl'>All</p>
                <p className='text-outline arc-black text-5xl lg:text-8xl md:translate-x-10' >Illustration</p>
              </div>
            </Animation>
            <Animation duration={animationDuration} animate={animate} delay="700" enter="opacity-0 left-[100%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
              <div className='flex flex-col items-center justify-center'>
                <p className='text-center text-2xl lg:text-3xl mx-6  w-[300px] lg:w-[500px] mx-auto'>My entire collection available for purchase</p>
                <RouterButton to="/aboutproduct" style={`${screenSize === "desktop" ? "dark" : "transparent"}`} text="Tell me more" />
                <HrefButton className={`${screenSize === "desktop" ? "hidden" : null}`} href="#prod" style="dark" text="Go to products" />
              </div>
            </Animation>
          </div>
          {/*vertical carousel container*/}
          <div className='flex items-center justify-center' id="prod">
            {
              products.length > 0 ? <ShopCarousel screenSize={screenSize} scroll={scroll}  animate={animate} products={products} addToCart={addToCart} /> : <Loading />
            }
          </div>
        </div>
      </div>
    </Animation>
  )
}
