import React, { useState, useRef } from 'react'
import Commerce from '../lib/Commerce';
import FnButton from '../components/buttons/FnButton';
import AwaitButton from '../components/buttons/AwaitButton'
import { Link, useNavigate, useParams } from "react-router-dom";
import Gradient from '../components/Gradient'

import Animation from '../components/Animation';
import RouterButton from '../components/buttons/RouterButton';

import { FiAlertTriangle } from 'react-icons/fi';

//stripe
import StripeElement from '../components/stripe/StripeElement';

export default function Checkout({ screenSize, getCheckoutToken, sendOrder, getCart, cartItems, cart }) {

    const animationDuration = 1000;

    const navigate = useNavigate();
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [street, setStreet] = useState(null);
    const [city, setCity] = useState(null);
    const [zipCode, setZipCode] = useState(null);
    const [country, setCountry] = useState(null);
    const [addittionalData, setAddittionalData] = useState(null)

    //input element
    const firstNameElement = useRef(null);
    const lastNameElement = useRef(null);
    const emailElement = useRef(null);
    const streetElement = useRef(null);
    const cityElement = useRef(null);
    const postalCodeElement = useRef(null);
    const countryElement = useRef(null);
    const termsAgreementElement = useRef(null);

    const [error, setError] = useState(false);

    const handleShipmentFormClick = () => {
        const res = checkForm();
        if (res != true) {
            res.current.scrollIntoView({
                behavior: 'smooth'
            });
            return false;
        }
        return true
    }

    const handleGoodPaymentResponse = async () => {
        const checkout = await getCheckoutToken();
        await sendOrder(checkout.id, orderData);
        await getCart();
        navigate("/thankyou", { replace: true })
    }

    const checkForm = () => {

        if (!firstNameElement.current.reportValidity() || firstName === null) return firstNameElement;
        if (!lastNameElement.current.reportValidity() || lastName === null) return lastNameElement;
        if (!emailElement.current.reportValidity() || email === null) return emailElement;
        if (!streetElement.current.reportValidity() || street === null) return streetElement;
        if (!cityElement.current.reportValidity() || city === null) return cityElement;
        if (!postalCodeElement.current.reportValidity() || zipCode === null) return postalCodeElement;
        if (!countryElement.current.reportValidity() || country === null) return countryElement;
        if (!termsAgreementElement.current.reportValidity()) return termsAgreementElement;

        return true;
    }

    if (!cartItems) {
        navigate("/", { replace: true });
    }

    const orderData = {
        customer: {
            firstname: firstName,
            lastname: lastName,
            email: email
        },
        shipping: {
            name: `${firstName} ${lastName}`,
            street: `${street} ${addittionalData != null ? "| " + addittionalData : ""}`,
            town_city: city,
            postal_zip_code: zipCode,
            country: country
        },
        fulfillment: {
            shipping_method: 'ship_7RyWOwmK5nEa2V'
        },
        payment: {
            gateway: 'test_gateway',
            card: {
                number: '4242424242424242',
                expiry_month: '02',
                expiry_year: '24',
                cvc: '123',
                postal_zip_code: '94107',
            },
        }
        /*         payment: {
                    gateway: 'stripe',
                    stripe: {
                        payment_method_id: null
                    },
                }, */
    }


    //stripe
    const [paymentReady, setPaymentReady] = useState(false);

    if (cartItems) {
        return (
            <Animation duration={animationDuration} delay="100" enter="opacity-0 top-[-300px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
                <div className={`w-screen flex justify-center items-center ${screenSize === "desktop" ? "my-24" : "mt-28 mb-52"}`}>
                    <Gradient blur={`${screenSize === "desktop" ? 200 : 100}`} color="#cbffcb" size={`${screenSize === "desktop" ? 400 : 300}`} top="40%" left="20%" />
                    {/* shipment form */}
                    <div className={`${screenSize === "desktop" ? "w-[40%]" : "w-[85%]"} ${paymentReady ? "hidden" : null} z-10`}>
                        <RouterButton to="/cart" text="Go back to cart" back style="transparent" />
                        <p className='text-4xl abril'>Checkout</p>
                        <p className={`${error ? "flex" : "hidden"} text-red-500 font-bold text-xl my-3 items-center justify-start`}><FiAlertTriangle className='mr-3' /> All of required input must be filled!</p>
                        <hr className='border-black my-2'></hr>
                        <div className='flex flex-col'>

                            <div className='flex flex-col my-2'>
                                <p className='text-xl abril'>Your data</p>
                                <input ref={firstNameElement} pattern="^[a-zA-Z]{3,20}$" required className={`${screenSize === "desktop" ? "input" : "input-mobile"} `} onChange={(e) => setFirstName(e.target.value)} placeholder='Name' type="text"></input>
                                <input ref={lastNameElement} pattern="^[a-zA-Z]{3,20}$" required className={`${screenSize === "desktop" ? "input" : "input-mobile"} `} onChange={(e) => setLastName(e.target.value)} placeholder='Surname' type="text"></input>
                                <input ref={emailElement} pattern="^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$" required className={`${screenSize === "desktop" ? "input" : "input-mobile"} `} onChange={(e) => setEmail(e.target.value)} placeholder='Email' type="email"></input>
                            </div>

                            <div className='flex flex-col my-2'>
                                <p className='text-xl abril'>Shipping details</p>
                                <input ref={streetElement} pattern="^[a-zA-Z0-9\s]*$" required className={`${screenSize === "desktop" ? "input" : "input-mobile"} `} onChange={(e) => setStreet(e.target.value)} autocomplete="street-address" placeholder='Street address' type="text"></input>
                                <input pattern="^[a-zA-Z0-9\s]*$" className={`${screenSize === "desktop" ? "input" : "input-mobile"} `} onChange={(e) => setAddittionalData(e.target.value)} autocomplete="addittional-data" placeholder='Additional address data' type="text"></input>
                                <input ref={cityElement} pattern="^[a-zA-Z]{3,20}$" required className={`${screenSize === "desktop" ? "input" : "input-mobile"} `} onChange={(e) => setCity(e.target.value)} placeholder='City' type="text"></input>
                                <input ref={postalCodeElement} pattern="^[0-9]{5}$" required className={`${screenSize === "desktop" ? "input" : "input-mobile"} `} onChange={(e) => setZipCode(e.target.value)} placeholder='Postal zip code' type="text"></input>
                                {/* <input className={`${screenSize === "desktop" ? "input" : "input-mobile"} `} onChange={(e) => setCountry(e.target.value)} placeholder='country' id="country" name="country" list="contry"></input> */}
                                <select ref={countryElement} required className={`${screenSize === "desktop" ? "input" : "input-mobile"} `} placeholder='country'
                                    onChange={(e) => {setCountry(e.target.value) }}>
                                    <option value="" disabled selected>Select Country</option>
                                    <option value="IT">Italy</option>
                                    <option value="CH">	Switzerland</option>
                                    <option value="FR">France</option>
                                    <option value="ES">Spain</option>
                                    <option value="PT">Portugal</option>
                                    <option value="DE">Germany</option>
                                    <option value="NL">Netherlands</option>
                                    <option value="DK">Denmark</option>
                                </select>

                                <div className='flex p-3 my-4 bg-slate-100 rounded-md'>
                                    <input ref={termsAgreementElement} required type={'checkbox'}></input>
                                    <p className='text-lg leading-5 mx-2'>I agree to the <Link to="/faq" className='text-[#9f3ce8] underline font-bold' >terms and conditions</Link>  as set out by the user agreement.</p>
                                </div>

                            </div>
                            <div className='flex justify-end'>
                                <FnButton text="Go to payment" style="dark"
                                    onClick={() => {
                                        setPaymentReady(handleShipmentFormClick());
                                    }} />
                            </div>
                        </div>
                    </div>

                    {/* card payment form */}
                    <div className={`${paymentReady ? null : "hidden"} ${screenSize === "desktop" ? "w-[40%]" : "w-[95%]"} rounded-md bg-[#f5f5f5] p-4 z-10`}>
                        <FnButton back text="go back" onClick={() => { setPaymentReady(false) }} style="transparent" />
                        <div className='p-2 mt-4 bg-[#00000012] rounded-md flex flex-col items-end'>
                            {
                                cart.line_items.map(el => {
                                    return (
                                        <div className='flex  justify-end border-b-[1px] border-[#00000038] mb-2 pb-2 '>
                                            <p className='mr-2 w-[100px] text-left'>{el.product_name}</p>
                                            <p className='mr-2 w-[30px] font-bold'>x{el.quantity} | </p>
                                            <p className='font-bold w-[70px]'>{el.line_total.formatted}€</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='my-3 w-auto flex flex-col items-end'>
                            <p className='abril'>Total</p>
                            <p className='arc-black text-5xl'>{cart.subtotal.formatted_with_symbol}</p>
                        </div>
                        <hr className='border-black' />
                        <StripeElement getCheckoutToken={getCheckoutToken} sendOrder={sendOrder} getCart={getCart} orderData={orderData} cart={cart} />
                    </div>

                </div>
            </Animation>
        )
    }
    return (
        <div></div>
    )

}
