import React, { useState } from 'react'
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import FnButton from '../buttons/FnButton';
import AwaitButton from '../buttons/AwaitButton';
import { useNavigate } from "react-router-dom";

import { FiAlertTriangle } from 'react-icons/fi';


export default function StripePaymentCard({ cart, getCheckoutToken, sendOrder, getCart, orderData }) {

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [paymentError, setPaymentError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleGoodPaymentResponse = async () => {
    const checkout = await getCheckoutToken();    
    await sendOrder(checkout.id, orderData);
    await getCart();
    navigate("/thankyou", { replace: true })
  }

  const sendPaymentRequest = async (event) => {
    setPaymentError(false)
    console.log("send payment req")
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      const body = {
        stripeId: paymentMethod.id,
        cartId: cart.id
      }
      //https://loopcubenew.herokuapp.com/payment
      //http://localhost:5000/payment
      const res = await fetch("https://loopcubenew.herokuapp.com/payment", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }).then(res => res.json());

      if (res.success) {
        return res;
      }
      setPaymentError(true);
      setErrorText(res.message + " , contact customer service");
      return false;

    } else {
      setPaymentError(true);
      setErrorText(error.message);
      return false;
    }
  };

  return (
    <div>
      <CardElement className='my-5 rounded-md bg-[white] p-7 ' />
      <p className={`${paymentError ? "" : "hidden"} flex items-center justify-center text-center bg-red-500 p-3 rounded-md text-white mb-3`}>
        <FiAlertTriangle className='mr-2' />{errorText}
      </p>
      <AwaitButton text="Pay now" style="dark"
        onClick={async () => {
          const res = await sendPaymentRequest();
          if (res) {
            if (res.success) {
              const sendToCommerceJS = await handleGoodPaymentResponse();
              return
            }
            return
          }
          return
        }} />
    </div>
  )
}
