import React from 'react'
import HrefButton from '../components/buttons/HrefButton'
import Eth from '../img/Ethereum-Logo.png'
import OpenSea from '../img/opensea-logo-white.png'
import Gradient from '../components/Gradient'
import NftImage from '../img/nft-placeholder.png'
import Animation from '../components/Animation'

export default function Nft({ screenSize, animate }) {

  const animationDuration = 1000;

  if (screenSize === "desktop") {
    return (
      <Animation duration={animationDuration} enter="opacity-0 left-[-100%]" normal="opacity-100 left-[0%]" exit="left-[-100%]">
        <div className='flex justify-center items-center w-screen min-h-screen bg-[#1E1E1E]'>
          <Gradient blur="100" color="#5c56a6" size="350" top="20%" left="50%" />
          <Gradient blur="200" color="#5c56a6" size="300" top="70%" left="90%" />
          <div className='z-10 my-36'>

            <Animation duration={animationDuration} animate={animate} delay="200" enter="opacity-0 top-[-500px]" normal="opacity-100 top-[0px]" exit="top-[-100%]">
              <div className='text-center flex flex-col justify-center items-center'>
                <p className='text-5xl abril'>NFT</p>
                <p className='text-xl abril'>Non-fungible Token</p>
                <hr className='border-black my-5 w-[300px] m-auto' />
                <p className='text-white text-6xl abril'>Become on owner of</p>
                <p className='text-white text-8xl arc-black text-outline-white mb-4'>DIGITAL ART</p>
                <HrefButton href="https://opensea.io/Loopcube_" text="Buy Now" style="white" />
                <div className='flex justify-center items-center'>
                  <img src={Eth} className="w-5 mx-2" />
                  <img src={OpenSea} className="w-[100px] mx-2" />
                </div>
              </div>
            </Animation>

            <Animation duration={animationDuration} animate={animate} delay="400" enter="opacity-0 top-[500px]" normal="opacity-100 top-[0px]" exit="top-[-100%]">
              <div className='flex justify-center items-start my-20'>
                <div className='w-[400px]'>
                  <p className='text-white abril m-auto text-left mb-11'>An NFT is a unit of data, stored on a type of digital ledger called a blockchain, which can be sold and traded.</p>
                  <img src={NftImage} className="block m-auto w-[300px]" />
                </div>
                <div className='h-[300px] border-white border-l-[1px] mx-11'></div>
                <div className='w-[400px]'>
                  <p className='text-left text-white mb-11'>
                    Prior to the existence of Cryptocurrency, we never really got to own something that was completely digital. We passed around videos and motion graphics, repurposing and reposting them, but there wasn’t this current opportunity to automatically assume complete, concrete ownership over a digital file or artwork. The rise of NFT’s changes this, allowing creators the authority to rent digital artworks out, to sell them or display them how they wish.
                    <br /> <br />
                    In order to sell them, designers need to get some kind of ‘legal’ ownership of their work. So, after NFT art is created, it’s ‘minted’ or tokenized on the cryptocurrency service, Blockchain. The Blockchain is a digital transaction system that records information in a way that makes it very difficult to hack or scam, which means it’s extremely useful for tracking copyright ownership and maintaining records of creation. Theoretically, any digital masterpiece you create and mint will lead solely to you.
                  </p>
                  <HrefButton href="https://opensea.io/Loopcube_" text="Buy Now" style="white" />
                  <div className='flex justify-start items-center'>
                    <img src={Eth} className="w-5 mx-2" />
                    <img src={OpenSea} className="w-[100px] mx-2" />
                  </div>
                </div>
              </div>
            </Animation>

          </div>
        </div>
      </Animation>
    )
  }
  if (screenSize === "mobile") {
    return (
      <Animation duration={animationDuration} enter="opacity-0 top-[500px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
        <div className='flex justify-center items-center w-screen min-h-screen bg-[#1E1E1E]'>
          <Gradient blur="100" color="#5c56a6" size="350" top="10%" left="50%" />
          <Gradient blur="200" color="#5c56a6" size="350" top="60%" left="50%" />
          <div className='z-10 my-24 mb-36'>

            <div className='text-center flex flex-col justify-center items-center'>
              <Animation duration={animationDuration} animate={animate} delay="500" enter="opacity-0 top-[-100px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
                <p className='text-3xl abril'>NFT</p>
                <p className='text-lg abril'>Non-fungible Token</p>
                <hr className='border-black my-5 w-[300px] m-auto' />
              </Animation>
              <Animation className="flex flex-col items-center" animate={animate} duration={animationDuration} delay="1000" enter="opacity-0 top-[200px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
                <p className='text-white text-3xl abril'>Become on owner of</p>
                <p className='text-white text-5xl arc-black text-outline-white mb-4'>DIGITAL ART</p>
                <HrefButton href="https://opensea.io/Loopcube_" text="Buy Now" style="white" />
                <div className='flex justify-center items-center'>
                  <img src={Eth} className="w-5 mx-2" />
                  <img src={OpenSea} className="w-[100px] mx-2" />
                </div>
                <div className="relative m-auto h-[100px] my-11 border-r-[1px] border-white"></div>
              </Animation>
            </div>


            <div className='w-[80%] m-auto'>
              <p className='text-white abril m-auto text-left mb-11'>An NFT is a unit of data, stored on a type of digital ledger called a blockchain, which can be sold and traded.</p>
              <p className='text-left text-white mb-11'>
                Prior to the existence of Cryptocurrency, we never really got to own something that was completely digital. We passed around videos and motion graphics, repurposing and reposting them, but there wasn’t this current opportunity to automatically assume complete, concrete ownership over a digital file or artwork. The rise of NFT’s changes this, allowing creators the authority to rent digital artworks out, to sell them or display them how they wish.
                <br /> <br />
                In order to sell them, designers need to get some kind of ‘legal’ ownership of their work. So, after NFT art is created, it’s ‘minted’ or tokenized on the cryptocurrency service, Blockchain. The Blockchain is a digital transaction system that records information in a way that makes it very difficult to hack or scam, which means it’s extremely useful for tracking copyright ownership and maintaining records of creation. Theoretically, any digital masterpiece you create and mint will lead solely to you.
              </p>
              <HrefButton href="https://opensea.io/Loopcube_" text="Buy Now" style="white" />
              <div className='flex justify-start items-center'>
                <img src={Eth} className="w-5 mx-2" />
                <img src={OpenSea} className="w-[100px] mx-2" />
              </div>
            </div>
          </div>
        </div>
      </Animation >
    )
  }

}
