import React from 'react'
import { BiUser } from 'react-icons/bi';
import { MdLocationCity } from 'react-icons/md';
import { TbTruckDelivery } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import RouterButton from '../components/buttons/RouterButton';
import Gradient from '../components/Gradient';
import Animation from '../components/Animation';

export default function ThankYou({ order, screenSize }) {

  const animationDuration = 1000;

  const navigate = useNavigate();
  if(!order){
    navigate("/", { replace: true });
  }

  if(order){
    return (
      <Animation duration={animationDuration} enter="opacity-0 top-[300px]" normal="opacity-100 top-[0px]" exit="left-[-100%]">
      <div className='w-screen h-screen flex flex-col items-center justify-around'>
      <Gradient blur={`${screenSize === "desktop" ? 200 : 100}`} color="#00800080" size={`${screenSize === "desktop" ? 400 : 300}`} top="40%" left="20%" />
        <div className={`flex-col ${screenSize === "desktop" ? "w-[70%]" : "w-[90%]" } z-10 flex items-start justify-center bg-[#fcfcfc] p-4 rounded-lg shadow-md`}>
          <p className='abril text-4xl'>Your order was placed</p>
          <p className='text-lg'>Check your email</p>
          <hr className='my-2 border-black w-[100%]' />
          <div className=''>
            <p className='arc-black text-2xl'>{order.order_value.formatted}€</p>
            <hr className='my-2 border-black w-[100%]' />
            <p className='flex items-center'><BiUser className='mr-2'/> {order.shipping.name}</p>
            <p className='flex items-center'><MdLocationCity className='mr-2'/> {order.shipping.town_city}</p>
            <p className='flex items-center'><TbTruckDelivery className='mr-2'/> {order.shipping.street}</p>
          </div>
          <hr className='my-2 border-black w-[100%]' />
          <RouterButton to="/home" text="Go back to home" style="dark" />
        </div>
      </div>
      </Animation>
    )
  }
  return(
    <div></div>
  )
}
