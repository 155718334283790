import React, { useState } from 'react'
import Commerce from '../../lib/Commerce'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Alert from '../Alert';


export default function RemoveAll({ id, removeFromCart }) {

  const loading = <AiOutlineLoading3Quarters className="animate-spin my-1" />;
  const remove = <AiOutlineCloseCircle className="" />;
  const [buttonText, setButtonText] = useState(remove);
  const [isAlert, setIsAlert] = useState(false);
  const alert = "Removed from cart"

  const handleRemove = async () => {
    setButtonText(loading);
    try {
      await removeFromCart(id);
      setButtonText(remove);
      setIsAlert(true);
      setTimeout(() => {
        setIsAlert(false);
      }, 1500);
    } catch (error) {
      setButtonText(remove);
    }
  }

  return (
    <div>
      {/* <Alert isAlert={isAlert} text={alert || ""} /> */}
      <button onClick={() => { handleRemove() }} className='m-1 scale-125'>{buttonText}</button>
    </div>

  )
}
